<template>
    <div class="bkColor">
        <headTitle :title="title" :routerPath="routerPath"></headTitle>
        <van-search v-model="value" input-align="center" placeholder="请输入搜索关键词" shape="round" show-action @search="onSearch"
            @cancel="onCancel" style="margin-bottom: 2vh" />
        <div class="search">
            <div>当前年份:</div>
            <div style="
            display: flex;
            justify-content: space-around;
            align-items: center">
                <van-dropdown-menu>
                    <van-dropdown-item v-model="queryFrom.currentYear" :options="yearOption" @change="changeYear" />
                </van-dropdown-menu>
                <div class="searchTitle">是否匹配:</div>
                <van-dropdown-menu>
                    <van-dropdown-item v-model="queryFrom.match" :options="matchOption" @change="changePjState" />
                </van-dropdown-menu>
                <div class="searchTitle">归属:</div>
                <van-dropdown-menu>
                    <van-dropdown-item v-model="queryFrom.belong" :options="belongOption" @change="changeBelong" />
                </van-dropdown-menu>
            </div>
        </div>
        <div class="list">
            <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
                <van-list v-model="loading" :finished="finished" :error.sync="error" error-text="请求失败，点击重新加载"
                    finished-text="没有更多了" @load="getIncome">
                    <div class="listCell" v-for="item in list" :key="item.id" @click="goDetails(item.id)">
                        <div class="cellName">{{ item.description }}
                            <div class="status">
                                <span class="green" v-if="item.relateNo">已支出</span>
                                <span class="red" v-else>未支出</span>
                            </div>
                        </div>
                        <div class="cellText" style="display: inline-block;">流水号：{{ item.gatheringNo }}
                        </div>
                        <div class="cellText">支出流水号：{{ item.relateNo ? item.relateNo : '暂无支出保证金流水号对应' }}</div>
                        <div class="cellText">收款金额：{{ item.price }}元</div>
                        <div class="cellText">收款日期：{{ format(item.collectionTime) }}</div>
                    </div>
                </van-list>
            </van-pull-refresh>
        </div>
        <!-- <div class="addBtn">
            <img src="../assets/icon/xz_icon.png" alt="" @click="add">
        </div> -->
        <div class="exportBtn" @click="exportFile">导出</div>
    </div>
</template>
  
<script>
import headTitle from "../components/headTitle.vue";
import Vue from 'vue';
import { Search, Toast, List, PullRefresh, Cell, SwipeCell, Button, Dialog } from 'vant';

import { income, incomeExport } from '../api/api'
import { yearOption } from '../units/units'

Vue.use(Search);
Vue.use(Toast)
Vue.use(List)
Vue.use(PullRefresh)
Vue.use(Cell);
Vue.use(SwipeCell)
Vue.use(Button)
Vue.use(Dialog)
export default {
    name: 'marginIncome',
    components: {
        headTitle
    },
    data() {
        return {
            title: '保证金收入',
            yearOption: yearOption,
            matchOption: [
                { text: "全部", value: null },
                { text: "未匹配", value: 0 },
                { text: "已匹配", value: 1 },
            ],
            belongOption: [
                { text: "全部", value: null },
                { text: "大美", value: "1" },
                { text: "中创", value: "2" },
                { text: "广云仓", value: "3" },
                { text: "大丰年", value: "4" },
            ],
            value: '',
            list: [],
            loading: false,
            finished: false,
            refreshing: false,
            error: false,
            queryFrom: {
                currentYear: null,
                match: null,
                belong: null,
                vague: "",
                pageSize: 10,
                pageNum: 1,
            },
            routerPath: '/',
            equipment: false
        }
    },
    mounted() {
        console.log(this.$route.query.routerPath)
        if (this.$route.query.routerPath) {
            this.routerPath = this.$route.query.routerPath
        }
        this.queryFrom.currentYear = this.$route.query.year * 1 || null
        this.queryFrom.belong = this.$route.query.belong > 0 ? this.$route.query.belong : null
        this.getIncome()
        this.isMobile()
    },
    methods: {
        getIncome() {
            this.loading = true;
            income(this.queryFrom).then((res) => {
                this.loading = false;
                this.list = this.list.concat(res.data.rows);
                this.refreshing = false;
                this.queryFrom.pageNum = this.queryFrom.pageNum + 1;
                if (res.data.total <= (this.queryFrom.pageNum - 1) * this.queryFrom.pageSize) {
                    this.finished = true;
                }
            });
        },
        stateText(t) {
            return t == 0 ? '没有匹配' : '匹配成功'
        },
        changeYear(val) {
            this.queryFrom.currentYear = val;
            this.onRefresh();
        },
        changePjState(val) {
            this.queryFrom.match = val;
            this.onRefresh();
        },
        changeBelong(val) {
            this.queryFrom.belong = val;
            this.onRefresh();
        },
        onSearch(val) {
            this.queryFrom.vague = val
            this.onRefresh()
        },
        onCancel() {
            this.queryFrom.vague = ''
            this.onRefresh()
        },
        onRefresh() {
            // 清空列表数据
            this.finished = false;
            this.queryFrom.pageNum = 1
            this.list = []
            // 重新加载数据
            // 将 loading 设置为 true，表示处于加载状态
            this.getIncome();
        },
        goDetails(id) {
            this.$router.push({
                path: '/collectionDetails', query: { id: id, routerPath: '/marginIncome' }
            })
        },
        format(time) {
            if (time) {
                return time.replace(' 00:00:00', '')
            }
        },
        exportFile() {
            if (!this.equipment) {
                Toast('此功能目前只能电脑端使用！')
                return
            }
            incomeExport(this.queryFrom).then((res) => {
                var blob = new Blob([res.data], {
                    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                });
                if ("download" in document.createElement("a")) {
                    // 非IE下载
                    var elink = document.createElement("a");
                    elink.download = "保证金支出列表";
                    elink.style.display = "none";
                    elink.href = URL.createObjectURL(blob);
                    document.body.appendChild(elink);
                    elink.click();
                    URL.revokeObjectURL(elink.href); // 释放URL 对象
                    document.body.removeChild(elink);
                } else {
                    // IE10+下载
                    navigator.msSaveBlob(blob, "保证金支出列表");
                }
            });
        },
        isMobile() {
            this.equipment = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i) ? false : true
        }
    }

}
</script>
  
<style lang="less" scoped>
.blue {
    color: #1989fa
}

.red {
    color: #E86F61;
}

.green {
    color: #00AB45;
}

.bkColor {
    width: 100vw;
    min-height: 100vh;
    background: #F6F6F6;
}

.search {
    width: 100vw;
    height: 8vh;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2vh;
    background: #fff;
    font-size: 15px;
    line-height: 8vh;
}

.searchTitle {
    margin-left: 2vw;
}

/deep/ .van-dropdown-menu__title {
    padding: 0 8px 0 0;
}

.list {
    margin-top: 2vh;
}


.listCell {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: 0 5vw 1vh;
    background: #fff;
    text-align: left;
}

.cellName {
    width: 90%;
    font-size: 1rem;
    font-weight: 600;
    border-top: 2px solid #F6F6F6;
    padding-top: 1vh;
    padding-bottom: 1vh;

    .status {
        float: right;
        font-size: 0.8rem;
        font-weight: 500;
    }
}

/deep/ .van-list :first-of-type.listCell .cellName {
    border-top: unset;
}

.cellText {
    width: 70%;
    color: #797979;
    margin-bottom: 0.5vh;
    font-size: 0.7rem;
    word-break: break-all;
    line-height: 1;
}

// .addBtn {
//     width: 70px;
//     height: 70px;
//     position: fixed;
//     top: 77%;
//     left: 77%;

//     img {
//         width: 70px;
//         height: 70px;
//     }
// }

.exportBtn {
    width: 50px;
    height: 50px;
    position: fixed;
    top: 77%;
    left: 77.1%;
    background: #3495fc;
    border-radius: 50%;
    line-height: 50px;
    color: #fff;
    box-shadow: 0px 6px 8px 0px rgba(122, 117, 117, 0.39);
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
</style>